import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import perspectives from '../../images/perspectives.png'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const PerspectivesPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Perspectives in Rhythm </div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={perspectives} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                "Velvet Brown is a protégé of the great Roger Bobo...and she has
                obviously picked up a lot of feisty pointers from the master.
                One is certainly the sound - no flatulent, inarticulate
                oompah-pahs here, but a focused, rock-solid tone that has as
                many echoes of the trombone as what we normally associate with
                the tuba. She is technically adept in every facet of playing and
                her ability to delineate clean, separate fast notes with
                non-blurred articulation is quite stunning." --Fanfare, May/Jun
                2007
              </p>
              <p className='my-4'>
                "Velvet Brown demonstrates tremendous range, technique and
                flexibility throughout this recording. More impressive than
                this, however, are her rhythmic style and her ability and
                willingness to play with a variety of sounds and articulations
                to best match the music. She shows herself to be a well-rounded
                and creative soloist." --ITA Journal, Apr. 2008
              </p>
              <p className='my-4'>
                "New music is what the tuba has had to be about in the years
                since the early 1970's. It's now time to take it to the next
                level. Velvet Brown is making a place for herself in the tuba
                world by doing just that. Every tubist needs to have this CD in
                their collection!" --NACWPI Journal, Fall 2007
              </p>
              <p className='my-4'>
                <a
                  href='https://www.amazon.com/Perspectives-Sound-Rhythm-Velvet-Brown/dp/B000MR9YEY/ref=sr_1_1?dchild=1&qid=1612371270&refinements=p_32%3A+Velvet%5CcBrown&s=music&sr=1-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  Perspectives in Rhythm can be purchased here
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={perspectives} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              "Velvet Brown is a protégé of the great Roger Bobo...and she has
              obviously picked up a lot of feisty pointers from the master. One
              is certainly the sound - no flatulent, inarticulate oompah-pahs
              here, but a focused, rock-solid tone that has as many echoes of
              the trombone as what we normally associate with the tuba. She is
              technically adept in every facet of playing and her ability to
              delineate clean, separate fast notes with non-blurred articulation
              is quite stunning." --Fanfare, May/Jun 2007
            </p>
            <p className='my-4'>
              "Velvet Brown demonstrates tremendous range, technique and
              flexibility throughout this recording. More impressive than this,
              however, are her rhythmic style and her ability and willingness to
              play with a variety of sounds and articulations to best match the
              music. She shows herself to be a well-rounded and creative
              soloist." --ITA Journal, Apr. 2008
            </p>
            <p className='my-4'>
              "New music is what the tuba has had to be about in the years since
              the early 1970's. It's now time to take it to the next level.
              Velvet Brown is making a place for herself in the tuba world by
              doing just that. Every tubist needs to have this CD in their
              collection!" --NACWPI Journal, Fall 2007
            </p>
            <p className='my-4'>
              <a
                href='https://www.amazon.com/Perspectives-Sound-Rhythm-Velvet-Brown/dp/B000MR9YEY/ref=sr_1_1?dchild=1&qid=1612371270&refinements=p_32%3A+Velvet%5CcBrown&s=music&sr=1-1'
                target='_blank'
                rel='noreferrer'
              >
                Perspectives in Rhythm can be purchased here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default PerspectivesPage
